import React, { useState, useEffect, useRef }  from "react";
import { Card, Figure } from "react-bootstrap";
import Slider from "react-slick";
import { 
  doubleSliders, sliderContent, sliderImg, fistSlider, secondSlider,
  sliderCard, cardImg, cardBody, cardTitle,  scIframe, scTitle,
  fImg, scAuthor, mobAuthorBox, mobAuthorContent, macTitle,
  mobQuoteBox, mqbContent, mqbQuote, mqbAuthor, mobIframe, mqbBtn
} from "./style.mod.scss";
import getImagePath from "helpers/get-image-path";

const DoubleSlider = ({ data }) => {
  const { doubleSliderData } = data;
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  let _slider1 = useRef(null);
  let _slider2 = useRef(null);

  useEffect(() => {
    setNav1(_slider1);
    setNav2(_slider2);
  });


  const settings = {
    infinite: false,
    className: "testtt",
    rows: 1,
    centerPadding: "60",
    speed: 500,
    slidesToShow: 4,
    dots: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        }
      },
    ]
  };

  const settings2 = {
    rows: 1,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    dots: false,
  };

  return (
    <div className={doubleSliders}>
      <div className={fistSlider}>
        <Slider
          asNavFor={nav2}
          ref={slider => (_slider1 = slider)}
          {...settings2}
        >
          {doubleSliderData.map((data) => {
            return(
              <div key={data._key}>
                <div className={sliderContent}>
                  <iframe className={scIframe} width="560" height="315" src={data.youtubeUrl} title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                  <h3 className={scTitle}>{data.quote}</h3>
                  <img className={sliderImg} src={getImagePath(data.avatarImg, true)}/>
                  <p className={scAuthor}>{data.name}</p>
                </div>
                <iframe className={mobIframe}  src={data.youtubeUrl} title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                <div className={mobAuthorBox} style={{ 
                  backgroundImage:`url(${getImagePath(data.sliderImg, true)})`,
                  height: "500px",
                  width: "100%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat" 
                }}>
                  <div className={mobAuthorContent}>
                    <p className={macTitle}>{data.olavText}</p>
                  </div>
                </div>
                <div className={mobQuoteBox}>
                  <div className={mqbContent}>
                    <p className={mqbQuote}>{data.quote}</p>
                    <p className={mqbAuthor}>{data.chefName}</p>
                  </div>
                  <button href={data.btnUrl} className={mqbBtn}>{data.btnText}</button>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      <div className={secondSlider}>
        <Slider
          asNavFor={nav1}
          ref={slider => (_slider2 = slider)}
          {...settings}
          swipeToSlide={true}
          focusOnSelect={true}
        >
          {doubleSliderData.map((data) => {
            return(
              <div key={data._key}>
                <Card className={sliderCard}>
                  <Card.Img className={cardImg} src={getImagePath(data.sliderImg, true)}/>
                  <Card.Body className={cardBody}>
                    <Card.Title className={cardTitle}>{data.chefName}
                      <Figure>
                        <Figure.Image
                          className={fImg}
                          width={8}
                          height={8}
                          alt="171x180"
                          src="https://imgur.com/1HT58Kw.png"
                        />
                      </Figure>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default DoubleSlider;
