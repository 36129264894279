// extracted by mini-css-extract-plugin
export var doubleSliders = "style-mod--double-sliders--2kUoY";
export var fistSlider = "style-mod--fist-slider--1v1sd";
export var sliderContent = "style-mod--slider-content--39EgQ";
export var scIframe = "style-mod--sc-iframe--Vi-wH";
export var scTitle = "style-mod--sc-title--16CHq";
export var scAuthor = "style-mod--sc-author--1ph6d";
export var sliderImg = "style-mod--slider-img--1cxnR";
export var mobIframe = "style-mod--mob-iframe--36UAa";
export var mobAuthorBox = "style-mod--mob-author-box--do3wL";
export var mobAuthorContent = "style-mod--mob-author-content--TH4Cq";
export var macTitle = "style-mod--mac-title--e_K21";
export var macBtn = "style-mod--mac-btn--3DZm7";
export var mobQuoteBox = "style-mod--mob-quote-box--2xc6l";
export var mqbContent = "style-mod--mqb-content--lYz5C";
export var mqbQuote = "style-mod--mqb-quote--2RwEW";
export var mqbAuthor = "style-mod--mqb-author--3Ydtu";
export var mqbBtn = "style-mod--mqb-btn---u46d";
export var secondSlider = "style-mod--second-slider--3R5le";
export var sliderCard = "style-mod--slider-card--30eH8";
export var cardBody = "style-mod--card-body--2VZgW";
export var cardTitle = "style-mod--card-title--1C0Fc";
export var cardImg = "style-mod--card-img--17mVd";
export var fImg = "style-mod--f-img--3adqm";